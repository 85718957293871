html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    font: 300 18px/20px robotolight, Helvetica, Arial, sans-serif;
    color: $black;     
}

h1 {
    font-size: 40px;
    line-height: 60px;
    text-transform: uppercase;
}

h2 {
    font-size: 24px;
    line-height: 50px;
    text-transform: uppercase;
    color: $dark-green;
    text-align: center;
}

h3 {
    font-size: 20px;
}

a {
    color: $dark-green;
    transition: color .3s linear;
    &:hover {
        color: #0d9679;
    };
}

p, li {
    line-height: 26px;
    font-size: 16px;
    color: $grey80;
}

header {
    background: #fff;
    width: 100%;
    opacity: 1;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    -ms-transition: height 0.3s;
    -o-transition: height 0.3s;
    transition: height 0.3s;
}

.top-bar {
    background: #fff;
    padding: 0;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #ccc;
}

.sticky .menu li a {
    font-size: 15px;
    padding: 11px 0;
    margin: 0 15px;
    font-weight: 400;
}

.sticky .submenu {
    top: 37px;
    left: -6px;
}

.sticky .submenu li a {
    padding: 8px 0;
    margin: 0 10px;
}

.sticky .logo {
    width: 80%;
    height: auto;
    top: 5px;
}

.button {
    background: $dark-green;
    padding: 10px 20px;
    margin: 20px 0;
    border-radius: 20px;
    color: $light-grey;
    display: table;
    transition: background .3s linear;
    &:hover {
        background: #0d9679;
    };
}

blockquote {
    display:block;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    text-indent: 40px;

    &::before {
        content: "\201C";
        font-family: Georgia, serif;
        font-size: 70px;
        color: #999;
        position: absolute;
        left: -38px;
        top: 18px;
    }

    em {
        font-style: italic;
    }
}

ul {
    li {
        line-height: 26px;
        font-size: 16px;
        list-style-type: disc;
        list-style-position: inside;
    }
}

#main-page {
    display: none;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

#landing-page {
    min-height: 100vh;
    background-color: #ccc;
    padding: 0;
    margin: 0;
    background: 
    linear-gradient(
      rgba(7, 91, 73, 0.1), 
      rgba(7, 91, 73, 0.1)
    ),
    url(../../images/landing-page-bg.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#landing-page .button{
    margin: 0; 
    padding: 7px 40px;
    font-size: 26px;
    background-color: #f7f7f7; 
    color: $dark-green;
    transition: background .3s linear;
    &:hover {
        background: #0d9679;
    };
}

#landing-page .content {
    position: relative;
    top: 40%;
    transform: translateY(20%);
}

#landing-page p, #landing-page h1, #landing-page h3 {
    color: #f7f7f7;
    text-shadow: 2px 2px 8px #000;
}

#landing-page p {
    font-size: 24px;
    line-height: 140px;
}

#landing-page h1 {
    font-size: 80px;
    line-height: 120px;
    padding: 0;
}

#landing-page h3 {
    font-size: 32px;
    line-height: 40px;
}

.owl-carousel .owl-wrapper-outer {
    min-height: 168px;
}

.menu {
    li {
        background: $white;
        position: relative;

        &.active {
           > a {
                background: none;
                /*font-weight: 400;*/
                color: $dark-green;
            }
        }

        a {
            text-transform: uppercase;
            font-weight: 300;
            font-size: 18px;
            position: relative;
            display: block;
            margin: 0 20px;
            padding: 20px 0;
            -webkit-transition: font-size 0.3s;
            -moz-transition: font-size 0.3s;
            -ms-transition: font-size 0.3s;
            -o-transition: font-size 0.3s;
            transition: font-size 0.3s;


            &:hover {
                font-weight: 300;
                color: $dark-green;

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                transition: all .5s linear;
                width: 100%;
                opacity: 0;
            }
        }
    }
}

.side-nav {
    li {
        list-style: none;
        a {
            line-height: 32px;
        }
    }

    .active {
        font-weight: 400;
    }
}

.top-header {
    background: rgba(25,36,50,0.2);
    padding: 5px;
    /*color: $light-grey;*/
    font-size: 14px;
}

.submenu {
    display: none;
    position: absolute;
    left: 0;
    top: 58px;
    z-index: 300;
    border-top: 2px solid $dark-green;
    width: 220px;

    li {
        list-style: none;
        transition: background .3s linear;
        &:hover {
            background: $light-grey;
        }

        a {
            text-transform: capitalize;
            font-size: 16px;
            text-transform: inherit;
            padding: 10px 0;
            margin: 0 10px;
        }
    }
}

li:hover .submenu {
    display: block;
    animation: fadeIn .7s;
}

.submenu .row {
    background: #fff;
    margin: 0;
    padding: 0;
}

.large {
    width: 410px;
    margin: 0;
    padding: 0;
    left: 20px;
}

.submenu .column, .submenu .columns {
    margin: 0 !important;
    padding: 0 !important;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.side-nav {
}

.expanded {
    img {
        width: 100%;
    }
}

.carousel-container {
    position: relative;
}

.carousel-title {
    @include vertical-align;
    position: absolute;
    font-size: 60px;
    left: 36%;
    color: $dark-green;

    span {
        font-size: 26px;
        color: #fff;
        background: $dark-green;
        padding: 10px;
        border-radius: 6px;
    }
}

.global-padding {
    padding: 30px 0;
}

.full-width-grey {
    background: $light-grey;
    font-size: 20px;
    width: 100%;

    span {
        @include border-radius(10px);
        background: $dark-green;
        margin: 0 14px;
        padding: 5px;

        a {
            color: $white;
        }
    }

    .large-4 {
        padding: 0 75px;
    }
}
 
.category-box {
    @include border-radius(100%);
    border: 2px solid $dark-green;
    width: 260px;
    height: 260px;
    position: relative;

    .category-info {
        img {
            @include border-radius(100%);
        }

        &:hover {
            .overlay {
                opacity: 0.8;
            }

            img {
                /*transform: scale3d(1.1, 1.1, 1);*/
                transition: all 0.3s ease-in-out;
            }

            .category-details {
                @include vertical-align;
                position: absolute;
                z-index: 900;
                opacity: 1;
            }
        }
    }

    .category-details {
        @include vertical-align;
        position: absolute;
        opacity: 0;
        width: 80%;
        text-align: center;
        color: #ebebeb;

        h2 {
            color: #ebebeb;
            line-height: 40px;
        }

        p {
            color: $light-grey;
        }
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background: #075b49;
        transition: all 0.3s ease-in-out;
        @include border-radius(100%);
        opacity: 0;
    }
}

.page-title {
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    background: $light-grey;
    padding: 30px 0;
}

.page-subtitle {
    margin-bottom: 30px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;

    .title-sep-container {
        position: relative;
        height: 6px;
        -ms-flex-grow: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .sep-double {
        height: 6px;
        position: relative;
        width: 100%;
        border-top: 1px solid #E7E6E6;
        border-bottom: 1px solid #E7E6E6;
        top: 10px;
        left: 10px;
    }
}

.owl-controls {
    .owl-buttons {
        position: absolute;
        width: 100%;
        top: 50%;
        text-indent: -999;

        div.owl-prev,
        div.owl-next {
            width: 60px;
            height: 0;
            position: absolute;
            top: 0;
            right: 0;
            color: transparent;
            background: none;
        }

        .owl-next,
        .owl-prev {
            @include transform(rotate(90deg));

            &::before,
            &::after {
                content: '';
                position: absolute;
                background: $grey;
                top: 0;
                height: 100%;
                width: 50%;
            }
            &::before {
                left: 0;
                @include transform(skew(0deg, -45deg));
            }

            &::after {
                right: 0;
                @include transform(skew(0deg, 45deg));
            }
        }

        .owl-prev {
            left: 20px;
            @include transform(rotate(270deg));
        }
    }
}

#owl-text {
    padding-top: 20px;
}

.testimonials {
    .owl-pagination {
        bottom: -30px;
    }

    .owl-controls {
        margin: 60px 0;

        .owl-buttons {
            .owl-prev, .owl-next {
                width: 30px;
            }

            .owl-prev {
                left: -5%;
            }
            .owl-next {
                right: -5%;
            }
        }
    }
}

.icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    line-height: 40;
    vertical-align: middle;
    margin: 0 20px;
    &.phone-icon {
        background: url("../images/flat-phone-icon.svg");
    }
    &.address-icon {
        background: url("../images/map-icon.svg");
    }
    &.email-icon {
        background: url("../images/email-icon.svg");
    }
}

.owl-theme .owl-controls {
    margin-top: 0;
}

.owl-pagination {
    position: absolute;
    left: 50%;
    bottom: 20px;
}

#owl-images .owl-item img {
    display: block;
    width: 100%;
    height: auto;
    @include border-radius(3px);
}

.quote-container {
    position: relative;

    .quote-message {
        background: #f7f7f7;
        padding: 33px 30px 30px 60px;
        position: relative;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(198,198,198,0.15);
        -moz-box-shadow: 0px 0px 5px 0px rgba(198,198,198,0.15);
        box-shadow: 0px 0px 5px 0px rgba(198,198,198,0.15);

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            width: 0;
            height: 0;
            border: 0 solid transparent;
            border-width: 18px 23px;
            border-right-width: 0;
            border-top-color: #e8e8e8;
        }

        .quote-bg {
            width: 50px;
            height: 45px;
            position: absolute;
            top: 40px;
            left: -20px;
            text-align: center;
            line-height: 45px;
            color: #fff;
            line-height: 45px;
            background: #075b49;

            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: 100%;
                left: 0;
                width: 0;
                height: 0;
                border: 0 solid transparent;
                border-width: 12px 20px;
                border-right-width: 0;
                border-bottom-color: #044336;
            }

            i {
                font: normal 14px FontAwesome;
                text-align: center;
                color: #fff;
                line-height: 45px;
                display: inline-block;

                &:before {
                    content: '\f10d';
                    font-size: 20px;
                    font-style: normal;
                }
            }
        }
    }
}

.logo {
    width: 100%;
    top: 12px;
    position: relative;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;

}

.footer {
    background: rgba(25,36,50,0.97);
    padding: 30px 0;
    color: $light-grey;
    font-size: 16px;

    .logo {
        width: 200px;
    }

    h4 {
        color: #fff;
        position: relative;
        padding-bottom: 18px;
        border-bottom: 2px solid #374455;
        margin-bottom: 20px;
        margin-top: 15px;
        font-size: 22px;
    }

    ul {
        li {
            line-height: 20px;
            margin: 0 0 15px 0;
            list-style-type: none;
            color: #cbd1da;
        }

        a {
            font-size: 15px;
            color: #cbd1da;
        }
    }
}